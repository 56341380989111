export const data = [
  {
    icon: "",
    name: "home",
    path: "/home",
  },
  {
    icon: "",
    name: "account",
    path: "/account",
  },
  {
    icon: "",
    name: "myWaitingRoom",
    path: "/waitingroom",
  },
  {
    icon: "",
    name: "myReservation",
    path: "/myreservation",
  },
  {
    icon: "",
    name: "createWaitingRoom",
    path: "/createwaitingroom",
  },
  {
    icon: "",
    name: "aboutUs",
    path: "/aboutus",
  },
]
